<template>
  <div class="summary-panel">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="สรุปยอดขายประจำเดือน"
        :filter="filter"
        :hasFilter="false"
        class="title"
      >
      </HeaderPanel>
      <b-row class="mt-2 filter">
        <b-col>
          <InputSelect
            :options="monthList"
            v-model="filter.month"
            @onDataChange="handleChangeMonth"
          />
        </b-col>
        <b-col>
          <InputSelect
            :options="yearList"
            v-model="filter.year"
            @onDataChange="handleChangeYear"
            textField="nameTh"
            valueField="id"
          />
        </b-col>
      </b-row>
      <div class="d-none d-md-block">
        <div class="sub-header">
          <p>
            รายงานยอดขายเดือน
            <span>{{ monthList[parseInt(filter.month) - 1].text }}</span> ปี
            <span>{{ parseInt(filter.year) + 543 }}</span>
          </p>
          <div class="d-md-flex justify-content-between my-3">
            <p>
              ยอดรวม
              <span>{{ summary.grandTotal | numeral('0,0.00') }}</span> บาท
            </p>
            <p>
              ทั้งหมด
              <span>{{ summary.numTransaction | numeral('0,0') }}</span> รายการ
            </p>
          </div>
          <p>ยอดขายรายวัน</p>
        </div>
        <div class="bg-white border-red mt-3">
          <b-row>
            <b-col>
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                class="table-main"
                :no-border-collapse="false"
              >
                <template v-slot:cell(day_transaction)="data">
                  {{
                    new Date(
                      `${parseInt(filter.year) + 543}/${filter.month}/${
                        data.item.day_transaction
                      }`
                    ) | moment($formatDateNew)
                  }}
                </template>
                <template v-slot:cell(num_transaction)="data">
                  {{ data.item.num_transaction }}
                  ({{ data.item.completed }},{{ data.item.voided }},{{
                    data.item.returned
                  }})
                </template>
                <template v-slot:cell(grand_total)="data">
                  <div>{{ data.item.grand_total | numeral('0,0.00') }}</div>
                </template>
                <template v-slot:cell(action)="data">
                  <router-link
                    :to="{
                      path: '/transaction',
                      query: {
                        startDate: $moment(
                          `${filter.year}/${filter.month}/${data.item.day_transaction}`
                        ).format('YYYY-MM-DD'),
                        endDate: $moment(
                          `${filter.year}/${filter.month}/${data.item.day_transaction}`
                        ).format('YYYY-MM-DD')
                      }
                    }"
                  >
                    <u> ดูรายละเอียด</u>
                  </router-link>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-block d-md-none mobile-panel">
        <div class="flex-gap-4 w-100 px-3">
          <div class="summary-box">
            <div class="text-r-14">ยอดขายรวม (บาท)</div>
            <div class="text-m-20 mt-2">
              {{ summary.grandTotal | numeral('0,0.00') }}
            </div>
          </div>
          <div class="summary-box">
            <div class="text-r-14">รายการ</div>
            <div class="text-m-20 mt-2">
              {{ summary.numTransaction | numeral('0,0') }}
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between px-3 py-1 mt-3 summary-header"
        >
          <div>วันที่ / ยอดขาย</div>
          <div class="text-r-12 text-center">
            <div class="text-center">Total</div>
            <div class="text-center min-w-135px">(Complete, Void, Return)</div>
          </div>
        </div>
        <div v-if="isLoadingData" class="loading-panel">
          <OtherLoading />
        </div>
        <div
          v-for="item in items"
          :key="item.id"
          class="d-flex justify-content-between p-3 mb-2 summary-item"
        >
          <div>
            <div class="text-gray">
              {{
                new Date(
                  `${parseInt(filter.year) + 543}/${filter.month}/${
                    item.day_transaction
                  }`
                ) | moment($formatDateNew)
              }}
            </div>
            <div class="text-m-16">
              {{ item.grand_total | numeral('0,0.00') }}
            </div>
          </div>
          <div class="text-center min-w-135px">
            <div class="text-center">
              {{
                `${item.num_transaction} (${item.completed}, ${item.voided}, ${item.returned})`
              }}
            </div>
            <div class="text-center">
              <router-link
                :to="{
                  path: '/transaction',
                  query: {
                    startDate: $moment(
                      `${filter.year}/${filter.month}/${item.day_transaction}`
                    ).format('YYYY-MM-DD'),
                    endDate: $moment(
                      `${filter.year}/${filter.month}/${item.day_transaction}`
                    ).format('YYYY-MM-DD')
                  }
                }"
              >
                <u class="text-gray text-r-14 pointer">ดูรายละเอียด</u>
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="!isLoadingData && items.length == 0"
          class="nodata-panel text-gray"
        >
          ไม่พบข้อมูล
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import { mapGetters } from 'vuex';

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect
  },
  name: 'Summary',
  data() {
    return {
      fields: [
        {
          key: 'day_transaction',
          label: 'วันที่'
        },
        {
          key: 'grand_total',
          label: 'ยอดขาย'
        },
        {
          key: 'num_transaction',
          label: 'รายการ (Complete,Void,Return)'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take: 10,
        month: 0,
        year: 0
      },
      isLoading: true,
      summary: {
        grand_total: 0,
        num_transaction: 0
      },
      monthList: [
        { value: 1, text: 'มกราคม' },
        { value: 2, text: 'กุมภาพันธ์' },
        { value: 3, text: 'มีนาคม' },
        { value: 4, text: 'เมษายน' },
        { value: 5, text: 'พฤษภาคม' },
        { value: 6, text: 'มิถุนายน' },
        { value: 7, text: 'กรกฎาคม' },
        { value: 8, text: 'สิงหาคม' },
        { value: 9, text: 'กันยายน' },
        { value: 10, text: 'ตุลาคม' },
        { value: 11, text: 'พฤศจิกายน' },
        { value: 12, text: 'ธันวาคม' }
      ],
      yearList: []
    };
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },

  created: async function () {
    this.today = this.$moment().format('YYYY-MM-DDT00:00:00');
    this.filter.month = this.$moment().format('M');
    this.filter.year = this.$moment().format('YYYY');

    this.getMonthBranchTransaction();
    this.getYearList();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };

      this.isLoadingData = true;
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/get_summaryday_transaction/${payload.month}/${payload.year}/${this.branchId}`
        )
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = this.items.length;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getMonthBranchTransaction: async function () {
      let payload = { ...this.filter };
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/get_month_branch_transaction/${payload.month}/${payload.year}/${this.branchId}`
        )
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.summary = { ...data.detail };
            this.summary.grandTotal = data.detail.grand_total;
            this.summary.numTransaction = data.detail.num_transaction;
          }
        });
    },
    getYearList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_year_list`)
        .then(async data => {
          if (data.result == 1) {
            data.detail = await data.detail.map(item => {
              return {
                ...item,
                nameTh: item.year + 543
              };
            });
            this.yearList = data.detail;
          }
        });
    },
    handleChangeMonth(value) {
      this.filter.month = value;
      this.getList();
      this.getMonthBranchTransaction();
    },
    handleChangeYear(value) {
      this.filter.year = value;
      this.getList();
      this.getMonthBranchTransaction();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  thead th,
  .table td {
    min-width: 180px;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
.sub-header {
  font-size: 20px;
  font-weight: bold;
}

.summary-box {
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  text-align: center;
}

.summary-panel {
  padding: 16px;
}

.min-w-135px {
  min-width: 135px;
}

@media (max-width: 768px) {
  .summary-panel {
    padding: 0px;
  }
  .title,
  .filter {
    padding: 0 12px;
  }

  .title {
    padding-top: 12px;
  }

  .summary-header {
    background-color: #ebe5e5;
    align-items: center;
  }

  .summary-item {
    border-bottom: 1px solid #ebe5e5;
  }

  .nodata-panel {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
